import '../sass/bootstrap.scss';
import { Dropdown, Collapse, Tab, Modal, Tooltip } from "bootstrap";

window.bootstrap = {
    Dropdown,
    Collapse,
    Tab,
    Modal,
	Tooltip
}

initTooltips();

$(document).ajaxStop(function () {
	initTooltips();
});

function initTooltips() {
	var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
	/*var tooltipList = */tooltipTriggerList.map(function (tooltipTriggerEl) {
		return new window.bootstrap.Tooltip(tooltipTriggerEl);
	});
}